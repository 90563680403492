import { useLocation } from '@gatsbyjs/reach-router';
import { Script } from 'gatsby';
import React, { createContext, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Optional } from '../api/types';
import StorageKeys from '../storage';
import UpdatableScript from '../updatable-script';
import { PageType } from './uppr';

export type TrackingContextValue = {
  enabled?: boolean | null;
  enable: () => void;
  disable: () => void;
  setProductSku: (sku: Optional<string>) => void;
  setPageType: (pageType: Optional<PageType>) => void;
};

export const TrackingContext = createContext<TrackingContextValue>({
} as TrackingContextValue);

type Props = {
  children: ReactNode;
};

export default function TrackingProvider({ children }: Props): ReactElement {
  const [productSku, setProductSku] = useState<string>();
  const [pageType, setPageType] = useState<PageType>();
  const [enabled, setEnabled] = useState<boolean | null>();

  const location = useLocation();
  const purchasePath = useMemo(() => (
    location.pathname.search(/payment\/?$/) !== -1
  ), [location.pathname]);

  useEffect(() => {
    const stored = localStorage.getItem(StorageKeys.Privacy);
    setEnabled(stored !== null ? stored === 'true' : null);
  }, []);

  const enable = useCallback(() => {
    localStorage.setItem(StorageKeys.Privacy, 'true');
    setEnabled(true);
  }, []);

  const disable = useCallback(() => {
    localStorage.setItem(StorageKeys.Privacy, 'false');
    setEnabled(false);
  }, []);

  const value = useMemo(() => ({
    enabled,
    enable,
    disable,
    setProductSku,
    setPageType,
  } as TrackingContextValue), [enabled, enable, disable]);

  const productSkuParam = useMemo(() => (
    productSku ? `&pid=${productSku}` : ''
  ), [productSku]);
  const pageTypeParam = useMemo(() => (
    pageType ? `&pagetype=${pageType}` : ''
  ), [pageType]);

  return (
    <>
      {enabled && (
        <>
          <Script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${process.env.GATSBY_META_PIXEL_ID}');`}
          
          </Script>

          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_ID}`}
          />
          <Script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '747925983');
            gtag('config', '${process.env.GATSBY_GOOGLE_TAG_ID}');
            gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}');
            `}
          </Script>

          <UpdatableScript
            src={`https://trck.hamburgdistilling.com/trck/etms/eatms.js?campaign_id=${process.env.GATSBY_UPPR_CAMPAIGN_ID}&consent=1${productSkuParam}${pageTypeParam}`}
            referrerPolicy="no-referrer-when-downgrade"
          />

          {purchasePath && (
            <Script src="https://trck.hamburgdistilling.com/trck/ebasket/ebasket.js" />
          )}

          <Script>
            {`!function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
            Array.prototype.slice.call(arguments))};var
            n=window.pintrk;n.queue=[],n.version="3.0";var
            t=document.createElement("script");t.async=!0,t.src=e;var
            r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${process.env.GATSBY_PINTEREST_TAG_ID}');
            pintrk('setconsent', true);
            pintrk('page');`}
          </Script>

          <img
            src={`https://ct.pinterest.com/v3/?tid=${process.env.GATSBY_PINTEREST_TAG_ID}&event=init&noscript=1`}
            style={{ display: 'none' }}
            alt=""
          />
        </>
      )}

      <TrackingContext.Provider value={value}>
        {children}
      </TrackingContext.Provider>
    </>
  );
}
