import { Link } from 'gatsby';
import React, { MouseEventHandler, ReactElement, RefObject, useContext } from 'react';
import CartDrawerItems from '../cart-drawer-items/cart-drawer-items';
import { CartContext } from '../../shared/cart/cart-provider';
import Price, { addToPrice } from '../../shared/price/price';
import Drawer, { DrawerSide } from '../drawer/drawer';
import './cart-drawer.scss';
import CartShippingStatus from '../../shared/cart/cart-shipping-status/cart-shipping-status';
import { navigate } from 'gatsby';

type Props = {
  triggerRefs: RefObject<HTMLElement>[];
  onClose: MouseEventHandler<unknown>;
};

export default function CartDrawer({ triggerRefs, onClose }: Props): ReactElement {
  const { cart, isLoading } = useContext(CartContext);

  const handleNavigate = (e:any) => {
    onClose(e);
    navigate('/checkout');
  }

  return (
    <Drawer
      className="cart-drawer pb-6"
      side={DrawerSide.Right}
      triggerRefs={triggerRefs}
      onClickOutside={onClose}
    >
      <header className="flex w-full justify-between sm:px-9 pb-3 pt-2 px-4 sm:mt-4">
        <h1 className='sm:text-xl text-md'>Korb</h1>
        <button type="button" className="button button--unstyled" onClick={onClose}>
          <i className="icon icon--times">Schließen</i>
        </button>
      </header>

      <div className="cart-drawer__body py-4">
        <div className='mb-4'>
          <CartShippingStatus totals={cart?.totals}/>
        </div>

        <CartDrawerItems />
      </div>

      <footer className="px-4 sm:px-9">
        <div className='border-top pt-4'>
          <span className="text-bold text-lg mr-3">
            <Price
              currency={cart?.totals}
              value={addToPrice(cart?.totals.total_items, cart?.totals.total_items_tax)}
            />
          </span>
          inkl. MwSt.
        </div>
        <div className="mt-3">
          +
          {' '}
          <Price currency={cart?.totals} value={addToPrice(cart?.totals.total_shipping, cart?.totals.total_shipping_tax)} />
          {' '}
          Versandkosten
        </div>

        <div className="text-right mt-4 mb-6">
          {!!cart?.items_count && (
            <button
              disabled={isLoading}
              className="button button--beige-light"
              onClick={(e) => handleNavigate(e)}>
                {!isLoading ? "Zum Warenkorb" :
                <span className="async-button__loader my-2 mx-4">
                  Lade
                </span>}
            </button>
          )}
        </div>

      </footer>
    </Drawer>
  );
}
