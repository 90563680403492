import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { ApiContext } from '../../api/api-provider';
import { CartData, Shipping } from '../../api/types';
import { addToPrice, getPriceAmount } from '../../price/price';
import './cart-shipping-status.scss';
import { CartContext } from '../cart-provider';

type Props = {
  totals?: CartData['totals'];
  className?: string;
};

export default function CartShippingStatus({
  totals,
  className,
}: Props): ReactElement | null {
  const [shippingRateFreeAmount, setShippingRateFreeAmount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { cart } = useContext(CartContext);
  const depositProductId = process.env.GATSBY_DEPOSIT_PRODUCT_ID 

  // products with deposit 
  const depositProducts = cart?.items.filter(item => item.id === Number(depositProductId)) || [];

  // total deposit price amount 
  const totalDeposit = Number(getPriceAmount(depositProducts[0]?.totals?.line_total, totals)) || 0;

  const { client } = useContext(ApiContext);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setIsLoading(true)
        const response = await client.get<Shipping>('/hdc/v1/shipping');
        setShippingRateFreeAmount(Number(response.data.shipping_rate_free.amount));
        setIsLoading(false)
      } catch(error) {
        console.error(error);
        setIsLoading(false)
      }
    })();
  }, [client]);

  const total = useMemo(() => Number(getPriceAmount(
    addToPrice(totals?.total_items, totals?.total_items_tax),
    totals,
  )), [totals]);
  
  const progress = useMemo(() => {
    if(!shippingRateFreeAmount || !total) return;
    const percent = Math.round(total / shippingRateFreeAmount * 100);
    return percent < 100 ? percent : 100;
  }, [shippingRateFreeAmount, total]);

  const remaining = useMemo(() => {
    if(!shippingRateFreeAmount || !total) return shippingRateFreeAmount;
    return shippingRateFreeAmount - total + totalDeposit;
  }, [shippingRateFreeAmount, total]);


  return (
    <div className={className}>
      <div className="cart-shipping-status__progress">
        <div className="cart-shipping-status__bar" style={{ width: `${progress === undefined ? 0 : progress}%` }}>
          {progress}%
        </div>
      </div>

      {!isLoading &&       
        <p className={`mt-1 cart-shipping-status__text ${isLoading ? '' : ''}`}>
          {remaining !== undefined && remaining > 0 ? (
            `Noch ${remaining.toFixed(2)} ${totals && totals.currency_symbol} bis zum kostenlosen Versand.`
          ) : (
            'Kostenloser Versand!'
          )}
        </p>
      }

      {isLoading && <p className='mt-1 cart-shipping-status__text text-white'>Berechne...</p>}


    </div>

  )
}
