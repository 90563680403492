import React, { MouseEventHandler, ReactNode, ReactPortal, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classes from '../../shared/classes';
import { useDocumentClick } from '../../shared/hooks/use-document-click';
import useLiftedContent from '../../shared/hooks/use-lifted-content';
import './drawer.scss';

export enum DrawerSide {
  Left = 'left',
  Right = 'right',
}

const NO_SCROLL_CLASS = 'no-scroll';

type Props = {
  side: DrawerSide;
  triggerRefs: RefObject<HTMLElement>[];
  onClickOutside: MouseEventHandler<unknown>;
  children: ReactNode;
  className?: string,
};

export default function Drawer({
  side,
  triggerRefs,
  onClickOutside,
  className,
  children,
}: Props): ReactPortal | null {
  const [offset, setOffset] = useState<number>();
  const elementRef = useRef<HTMLDivElement>(null);

  const target = useLiftedContent();

  const isTrigger = useCallback((node: Node) => triggerRefs.some(
    (ref) => ref.current?.contains(node),
  ), [triggerRefs]);

  useDocumentClick((event) => {
    const targetNode = event.target as Node;
    if(isTrigger(targetNode) || elementRef.current?.contains(targetNode)) {
      return;
    }

    onClickOutside(event as unknown as React.MouseEvent<unknown, MouseEvent>);
  });

  useEffect(() => {
    const body = document.querySelector('body');
    body?.classList.add(NO_SCROLL_CLASS);

    setOffset(document.querySelector('.site-header')?.getBoundingClientRect().bottom);

    return () => {
      body?.classList.remove(NO_SCROLL_CLASS);
    };
  }, []);

  if(!target) {
    return null;
  }

  return createPortal((
    <div
      ref={elementRef}
      className={classes(`drawer drawer--${side}`, className)}
      style={{ top: `${offset}px`, zIndex: 1000 }}
    >
      {children}
    </div>
  ), target);
}
