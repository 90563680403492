import { Link } from 'gatsby';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApiContext } from '../../shared/api/api-provider';
import AsyncButton from '../../shared/async-button/async-button';
import classes from '../../shared/classes';
import useTracking from '../../shared/tracking/use-tracking';

type NewsletterFormData = {
  name: string;
  email: string;
  consent: string;
};

export enum NewsletterFormVariant {
  White = 'white',
}

type Props = {
  variant?: NewsletterFormVariant;
  onSubscribed?: () => void;
  className?: string;
  referrerId?: string;

};

export default function NewsletterForm({
  variant,
  onSubscribed,
  className,
  referrerId = ''
}: Props): ReactElement {
  const [submitPromise, setSubmitPromise] = useState<Promise<unknown>>();

  const { client } = useContext(ApiContext);

  const { trackSubscribe } = useTracking();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsletterFormData>();

  const subscribe = useCallback(async ({ name, email }: NewsletterFormData) => {
    try {
      const promise = client.post('/hdc/v1/newsletter', {
        name,
        email,
        referrerId
      });
      setSubmitPromise(promise);

      await promise;
      trackSubscribe();
      onSubscribed?.();
    } catch {}
  }, [client, onSubscribed, trackSubscribe]);

  const submitInvalidData = useCallback(() => {
    setSubmitPromise(Promise.reject());
  }, []);

  return (
    <form
      className={classes(className, {
        'fg-white': variant === NewsletterFormVariant.White,
      })}
      onSubmit={handleSubmit(subscribe, submitInvalidData)}
    >
      <fieldset
        className={classes('form__field', {
          'form__field--white': variant === NewsletterFormVariant.White,
        })}
      >
        <input
          type="text"
          style={{padding: "16px 0"}}
          className={classes('control ', {
            'border-danger placeholder-danger': !!errors.name,
          })}
          placeholder="Name"
          {...register('name', { required: true })}
        />
      </fieldset>

      <fieldset
        className={classes('form__field mt-2 ', {
          'form__field--white': variant === NewsletterFormVariant.White,
        })}
      >
        <input
        style={{padding: "16px 0"}}
          type="email"
          className={classes('control', {
            'border-danger placeholder-danger': !!errors.email,
          })}
          placeholder="Mail"
          {...register('email', { required: true })}
        />
      </fieldset>

      <fieldset
        className={classes('form__field mt-4', {
          'form__field--white': variant === NewsletterFormVariant.White,
        })}
      >
        <label className={classes({ 'fg-danger': !!errors.consent })}>
          <input
            type="checkbox"
            className="control mr-2"
            {...register('consent', { required: true })}
          />
          <small>Ich stimme den Bedingungen zu.</small>
        </label>
      </fieldset>

      <p className="text-body-sm mt-3 text-xs opacity-50">
        Eine Abmeldung ist jederzeit möglich. Mehr Infos
        {' '}
        <Link className='underline' to="/common/privacy">
          hier
        </Link>
        .
      </p>

      <AsyncButton
        type="submit"
        className={classes('mt-5', {
          'button--beige': variant === NewsletterFormVariant.White,
          'button--beige-light': variant !== NewsletterFormVariant.White,
        })}
        promise={submitPromise}
      >
        Anmelden
      </AsyncButton>
    </form>
  );
}
